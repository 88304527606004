import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Checkbox,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { UserCreate } from "interfaces";

export const UserCreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<UserCreate>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
            label="Full name"
            name="full_name"
            rules={[
              {
                required: false
              },
            ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label="Is active"
            name="is_active"
            valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
            label="Is superuser"
            name="is_superuser"
            valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  );
};
