import { IResourceComponentsProps } from "@pankod/refine-core";
import {
    Checkbox,
    Edit,
    Form,
    Input,
    useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { UserUpdate } from "interfaces";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<UserUpdate>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          label="Full name"
          name="full_name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is active"
          name="is_active"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Is superuser"
          name="is_superuser"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Edit>
  );
};
