import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  BooleanField,
  useTable,
  getDefaultSortOrder,
  EmailField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { User } from "interfaces";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<User>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // const categoryIds =
  //   tableProps?.dataSource?.map((item) => item.category.id) ?? [];
  // const { data: categoriesData, isLoading } = useMany<ICategory>({
  //   resource: "categories",
  //   ids: categoryIds,
  //   queryOptions: {
  //     enabled: categoryIds.length > 0,
  //   },
  // });

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categories",
  // });
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="email"
          render={(value) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
            dataIndex="full_name"
            key="full_name"
            title="Full Name"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("full_name", sorter)}
            sorter
        />
        <Table.Column
            dataIndex="is_active"
            key="is_active"
            title="Active"
            render={(value) => <BooleanField value={value} />}
            defaultSortOrder={getDefaultSortOrder("is_active", sorter)}
            sorter
        />
        <Table.Column
            dataIndex="is_superuser"
            key="is_superuser"
            title="Superuser"
            render={(value) => <BooleanField value={value} />}
            defaultSortOrder={getDefaultSortOrder("is_superuser", sorter)}
            sorter
        />
        {/*<Table.Column*/}
        {/*  dataIndex="status"*/}
        {/*  key="status"*/}
        {/*  title="Status"*/}
        {/*  render={(value) => <TagField value={value} />}*/}
        {/*  defaultSortOrder={getDefaultSortOrder("status", sorter)}*/}
        {/*  sorter*/}
        {/*/>*/}
        {/*<Table.Column*/}
        {/*  dataIndex="createdAt"*/}
        {/*  key="createdAt"*/}
        {/*  title="Created At"*/}
        {/*  render={(value) => <DateField value={value} format="LLL" />}*/}
        {/*  defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}*/}
        {/*  sorter*/}
        {/*/>*/}
        {/*<Table.Column*/}
        {/*  dataIndex={["category", "id"]}*/}
        {/*  title="Category"*/}
        {/*  render={(value) => {*/}
        {/*    if (isLoading) {*/}
        {/*      return <TextField value="Loading..." />;*/}
        {/*    }*/}

        {/*    return (*/}
        {/*      <TextField*/}
        {/*        value={*/}
        {/*          categoriesData?.data.find((item) => item.id === value)?.title*/}
        {/*        }*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*  filterDropdown={(props) => (*/}
        {/*    <FilterDropdown {...props}>*/}
        {/*      <Select*/}
        {/*        style={{ minWidth: 200 }}*/}
        {/*        mode="multiple"*/}
        {/*        placeholder="Select Category"*/}
        {/*        {...categorySelectProps}*/}
        {/*      />*/}
        {/*    </FilterDropdown>*/}
        {/*  )}*/}
        {/*/>*/}
        <Table.Column<User>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
