import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, BooleanField } from "@pankod/refine-antd";

import { User } from "interfaces";

const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<User>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

    return (
    <Show isLoading={isLoading}>
      <Title level={5}>Email</Title>
      <Text>{record?.email}</Text>

      <Title level={5}>Full name</Title>
      <Text>{record?.full_name}</Text>

      <Title level={5}>Is Active</Title>
      <BooleanField value={record?.is_active} />

      <Title level={5}>Is Superuser</Title>
      <BooleanField value={record?.is_superuser} />
    </Show>
  );
};
