import { CrudFilters, HttpError, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Form,
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  ShowButton,
  Input,
  Button,
} from "@pankod/refine-antd";
import { PodcastInfo } from "interfaces";

interface PodcastFilterVariables {
  search: string
}

export const PodcastList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useTable<PodcastInfo, HttpError, PodcastFilterVariables>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push(
          {
              field: "search",
              operator: "eq",
              value: search,
          },
      );

      return filters;
    },
  });

  return (
    <List>
      <Form
          {...searchFormProps}
          layout="vertical"
          // onValuesChange={() => searchFormProps.form?.submit()}
      >
          <Space wrap>
              <Form.Item name="search">
                <Input />
              </Form.Item>
              <Form.Item>
                <Button onClick={() => searchFormProps.form?.submit()}>Search</Button>
              </Form.Item>
              
          </Space>
      </Form>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="itunes_id"
          key="itunes_id"
          title="iTunes ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("itunes_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column
            dataIndex="author"
            key="author"
            title="Author"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("author", sorter)}
            sorter
        />
        <Table.Column<PodcastInfo>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
