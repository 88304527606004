import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
    Edit,
    Form,
    Input,
    TextField,
    useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { PodcastUpdate, PodcastInfo } from "interfaces";

export const PodcastEdit: React.FC<IResourceComponentsProps> = () => {
  const { queryResult: showQueryResult } = useShow<PodcastInfo>();
  const { formProps, saveButtonProps, queryResult } = useForm<PodcastUpdate>({

  });

  const { data, isLoading } = showQueryResult;
  const record = data?.data;

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={isLoading}>
      <Form {...formProps} layout="vertical">
        
        <Form.Item
          label="iTunes ID"
          name="itunes_id"
        >
          <TextField value={record?.itunes_id}/>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
        >
          <TextField value={record?.title}/>
        </Form.Item>

        <Form.Item
          label="Author"
          name="author"
        >
          <TextField value={record?.author}/>
        </Form.Item>

        <Form.Item
          label="RSS"
          name="link_rss"
        >
          <a href={record?.link_rss} target="_blank">{record?.link_rss}</a>
        </Form.Item>

        <Form.Item
          label="iTunes"
          name="link_itunes"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Google"
          name="link_google"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Spotify"
          name="link_spotify"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Yandex"
          name="link_yandex"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soundstream"
          name="link_soundstream"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="VK"
          name="link_vk"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Overcast"
          name="link_overcast"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Pocketcasts"
          name="link_pocketcasts"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="PlayerFM"
          name="link_playerfm"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Castro"
          name="link_castro"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Breaker"
          name="link_breaker"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Castbox"
          name="link_castbox"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Stitcher"
          name="link_stitcher"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Radiopublic"
          name="link_radiopublic"
        >
          <Input />
        </Form.Item>
        
      </Form>
    </Edit>
  );
};
