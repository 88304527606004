import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { PodcastInfo } from "interfaces";

const { Title, Text } = Typography;

export const PodcastShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<PodcastInfo>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

    return (
    <Show isLoading={isLoading}>
      <Title level={5}>iTunes ID</Title>
      <Text>{record?.itunes_id}</Text>

      <Title level={5}>Title</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>Author</Title>
      <Text>{record?.author}</Text>

      <Title level={5}>RSS</Title>
      <Text><a href={record?.link_rss}>{record?.link_rss}</a></Text>

      <Title level={5}>iTunes</Title>
      <Text><a href={record?.link_itunes}>{record?.link_itunes}</a></Text>

      <Title level={5}>Google</Title>
      <Text><a href={record?.link_google}>{record?.link_google}</a></Text>

      <Title level={5}>Spotify</Title>
      <Text><a href={record?.link_spotify}>{record?.link_spotify}</a></Text>

      <Title level={5}>Yandex</Title>
      <Text><a href={record?.link_yandex}>{record?.link_yandex}</a></Text>

      <Title level={5}>VK</Title>
      <Text><a href={record?.link_vk}>{record?.link_vk}</a></Text>

      <Title level={5}>Soundstream</Title>
      <Text><a href={record?.link_soundstream}>{record?.link_soundstream}</a></Text>

      <Title level={5}>Castbox</Title>
      <Text><a href={record?.link_castbox}>{record?.link_castbox}</a></Text>

      <Title level={5}>Stitcher</Title>
      <Text><a href={record?.link_stitcher}>{record?.link_stitcher}</a></Text>

      <Title level={5}>Overcast</Title>
      <Text><a href={record?.link_overcast}>{record?.link_overcast}</a></Text>

      <Title level={5}>PocketCasts</Title>
      <Text><a href={record?.link_pocketcasts}>{record?.link_pocketcasts}</a></Text>

      <Title level={5}>PlayerFM</Title>
      <Text><a href={record?.link_playerfm}>{record?.link_playerfm}</a></Text>

      <Title level={5}>Castro</Title>
      <Text><a href={record?.link_castro}>{record?.link_castro}</a></Text>

      <Title level={5}>Breaker</Title>
      <Text><a href={record?.link_breaker}>{record?.link_breaker}</a></Text>

      <Title level={5}>RadioPublic</Title>
      <Text><a href={record?.link_radiopublic}>{record?.link_radiopublic}</a></Text>

    </Show>
  );
};
