import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  LoginPage,
  
} from "@pankod/refine-antd";
import {} from "@pankod/refine-kbar";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { AudioOutlined, UserOutlined } from "@ant-design/icons";
import { UserList, UserCreatePage, UserEdit, UserShow } from "pages/users";
import { PodcastList, PodcastShow, PodcastEdit } from "pages/podcasts";
import { OffLayoutArea } from "components/offLayoutArea";
import { authProvider, axiosInstance } from "./authProvider";

function App() {
  return (
    <RefineKbarProvider>
      <Refine
        notificationProvider={notificationProvider}
        Layout={Layout}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={routerProvider}
        dataProvider={dataProvider("/api/v1", axiosInstance)}
        authProvider={authProvider}
        LoginPage={LoginPage}
        resources={[
          {
            name: "podcasts",
            list: PodcastList,
            edit: PodcastEdit,
            show: PodcastShow,
            icon: <AudioOutlined/>
          },{
            name: "users",
            list: UserList,
            create: UserCreatePage,
            edit: UserEdit,
            show: UserShow,
            icon: <UserOutlined/>
          },
        ]}
        OffLayoutArea={OffLayoutArea}
      />
    </RefineKbarProvider>
  );
}

export default App;
