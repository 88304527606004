import axios, { AxiosRequestConfig } from "axios";
import { AuthProvider } from "@pankod/refine-core";

export const TOKEN_KEY = "refine-auth";
export const TOKEN_TYPE_KEY = "refine-auth-type";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      const token = localStorage.getItem(TOKEN_KEY);
      const token_type = localStorage.getItem(TOKEN_TYPE_KEY);
      const header_value = `${token_type} ${token}`;
      if (request.headers) {
        request.headers["Authorization"] = header_value
      } else {
        request.headers = {
          Authorization: header_value,
        };
      }

      return request;
    },
);

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await axios.post("/api/v1/login/access-token", {
      username: username,
      password: password
    })

    if (response.status === 400) {
      return Promise.reject(new Error(response.data.detail));
    }

    if (response.status !== 200) {
      return Promise.reject(new Error("Unexpected error"));
    }

    localStorage.setItem(TOKEN_KEY, response.data.access_token);
    localStorage.setItem(TOKEN_TYPE_KEY, response.data.token_type);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_TYPE_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const response = await axiosInstance.post("/api/v1/login/test-token");
    if (response.status === 200) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const response = await axiosInstance.post("/api/v1/login/test-token");
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject();
  },
};
